import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import cart from "./cart";
import checklist from "./checklist";
import Chat from "./Chat";
import Checkout from "./Checkout"
import Common from './Common'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: []
  },

  getters: {
    errors: state => state.errors
  },

  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    }
  },

  actions: {},

  modules: {
    auth,
    cart,
    checklist,
    Chat,
    Checkout,
    Common
  }
});
