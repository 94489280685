export default [
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    //{ path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/products/category', component: require('./components/product/Category.vue').default },
    { path: '/notifications', component: require('./components/Notifications.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default },


    {
        path: '/dashboard',
        component: () => import('./components/Dashboard/'),
        meta: {
            title:'dashboard',
        },
        children: [
            {
                path: "/",
                name: "DashboardCallLockStatus",
                component: () => import('./components/Dashboard/DashboardCallLockStatus.vue'),
                props: true,
                meta: {
                    title:'Dashboard Call Lock Status'
                },
            },
            {
                path: "/Dashboard/rm_cost",
                name: "DashboardRMCost",
                component: () => import('./components/Dashboard/DashboardRMCost.vue'),
                props: true,
                meta: {
                    title:'Dashboard RM Cost'
                },
            }
        ],
        props: true,
    },

    {
        path: '/vendors',
        name: 'Vendor',
        component: () => import('./components/Vendor/index.vue'),
        meta: {
            title: 'Vendor'
        },
    },
    {
        path: '/vendor/cluster',
        name: 'Cluster',
        component: () => import('./components/Vendor/cluster.vue'),
        meta: {
            title: 'Cluster'
        },
    },
    {
        path: '/products',
        name: 'product',
        component: () => import('./components/product/index.vue'),
        meta: {
            title: 'product'
        },
    },
    {
        path: '/products/add',
        name: 'productAdd',
        component: () => import('./components/product/productAddEdit.vue'),
        meta: {
            title: 'product'
        },
    },
    {
        path: '/products/edit/:id',
        name: 'productEdit',
        component: () => import('./components/product/productAddEdit.vue'),
        meta: {
            title: 'product Edit'
        },
    },
    {
        path: '/products/category',
        name: 'Category',
        component: () => import('./components/product/Category.vue'),
        meta: {
            title: 'Category'
        },
    },
    {
        path: '/products/usage_category',
        name: 'usage_category',
        component: () => import('./components/product/UsageCategory.vue'),
        meta: {
            title: 'Usage Category'
        },
    },
    {
        path: '/products/mat_category',
        name: 'mat_category',
        component: () => import('./components/product/MaterialCategory.vue'),
        meta: {
            title: 'Mat Category'
        },
    },

    {
        path: '/products/colour',
        name: 'colour',
        component: () => import('./components/product/Colours.vue'),
        meta: {
            title: 'Product Colours'
        },
    },
    {
        path: '/products/size',
        name: 'sizes',
        component: () => import('./components/product/Sizes.vue'),
        meta: {
            title: 'Product sizes'
        },
    },
    {
        path: '/products/qc_template',
        name: 'qc_template',
        component: () => import('./components/product/QualityControlTemplate'),
        meta: {
            title: 'QC Template'
        },
    },
    {
        path: '/products/qc_template_add',
        name: 'qc_template_add',
        component: () => import('./components/product/QualityControlTemplateAddEdit'),
        meta: {
            title: 'QC Template Add'
        },
    },
    {
        path: '/products/qc_template_edit/:id',
        name: 'qc_template_edit',
        component: () => import('./components/product/QualityControlTemplateAddEdit.vue'),
        meta: {
            title: 'QC Template Edit'
        },
    },
    {
        path: '/products/qc_checksheet',
        name: 'qc_checksheet',
        component: () => import('./components/product/QualityControlChecksheet'),
        meta: {
            title: 'QC Checksheet'
        },
    },
    {
        path: '/products/qc_checksheet_add',
        name: 'qc_checksheet_add',
        component: () => import('./components/product/QualityControlChecksheetAddEdit'),
        meta: {
            title: 'QC Checksheet Add'
        },
    },
    {
        path: '/products/qc_checksheet_edit/:id',
        name: 'qc_checksheet_edit',
        component: () => import('./components/product/QualityControlChecksheetAddEdit'),
        meta: {
            title: 'QC Checksheet Edit'
        },
    },
    {
        path: '/sales_channel',
        name: 'sales_channel',
        component: () => import('./components/SalesChannel/online.vue'),
        meta: {
            title: 'Online Sales Channel'
        },
    },
    {
        path: '/offline_sales_channel',
        name: 'offline_sales_channel',
        component: () => import('./components/SalesChannel/offline.vue'),
        meta: {
            title: 'Offline Sales Channel'
        },
    },
    {
        path: '/sales',
        name: 'sales',
        component: () => import('./components/Sales/index.vue'),
        meta: {
            title: 'Product sales'
        },
    },
    {
        path: '/forecast',
        name: 'forecast',
        component: () => import('./components/Forecast/index.vue'),
        meta: {
            title: 'Forecast'
        },
    },
    {
        path: '/inventory',
        name: 'inventory',
        component: () => import('./components/inventory/index.vue'),
        meta: {
            title: 'Forecast'
        },
    },
    {
        path: '/schedule',
        name: 'schedule',
        component: () => import('./components/schedule/index.vue'),
        meta: {
            title: 'Schedule'
        },
    },
    {
        path: '/report',
        name: 'report',
        component: () => import('./components/report/index.vue'),
        meta: {
            title: 'Report'
        },
    },
    
    {
        path: '/mela-template',
        name: 'mela-template',
        component: () => import('./components/mela/MelaTemplate.vue'),
        meta: {
            title: 'Mela Template'
        },
    },
    {
        path: '/mela-template-add',
        name: 'mela-template-add',
        component: () => import('./components/mela/MelaTemplateAddEdit'),
        meta: {
            title: 'Mela Template Add'
        },
    },
    {
        path: '/mela-template-edit/:id',
        name: 'mela-template-edit',
        component: () => import('./components/mela/MelaTemplateAddEdit.vue'),
        meta: {
            title: 'Mela Template Edit'
        },
    },
    {
        path: '/order-list',
        name: 'order-list',
        component: () => import('./components/mela/OrderList.vue'),
        meta: {
            title: 'Order List'
        },
    },
    {
        path: '/order-detail/:id',
        name: 'order-detail',
        component: () => import('./components/mela/OrderDetail.vue'),
        meta: {
            title: 'Order Detail'
        },
    },
    {
        path: '/dpr',
        name: 'dpr',
        component: () => import('./components/Dpr/index.vue'),
        meta: {
            title: 'DPR'
        },
    },
    {
        path: '/dpr-add-edit',
        name: 'dpr-add',
        component: () => import('./components/Dpr/DprAddEdit.vue'),
        meta: {
            title: 'DPR Add Edit'
        },
    },
    {
        path: '/dpr-add-edit/:id',
        name: 'dpr-edit',
        component: () => import('./components/Dpr/DprAddEdit.vue'),
        meta: {
            title: 'DPR Add Edit'
        },
    },
];
