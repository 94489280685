<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center" style="margin-top: 200px;">
                    <h1 style="font-size: 100px;">404</h1>
                    <h5>Page Not Found</h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        mounted() {
           
        }
    }
</script>
